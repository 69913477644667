import _ from 'lodash'
import { Content } from '@/presentation/components'
import { Image } from './styles'
import Img from '@/presentation/assets/images/home.png'
import { PageHeader, Panel } from '@/presentation/components/molecules'
import { Box } from '@/presentation/components/atoms'
import { useAtom, useAtomValue } from 'jotai'
import { AccountPersistentStore, GlobalThemePersistentStore } from '@/main/store'
import { useEffect } from 'react'
import { defaultTheme } from '@/config/themes/defaultTheme'
import banner from './banner.png'
import { decode } from 'jsonwebtoken'
const Home = () => {
  const account = useAtomValue(AccountPersistentStore)
  const [theme, setTheme] = useAtom(GlobalThemePersistentStore)
  useEffect(() => {
    if (!account.resale?.theme) return
    if (Object.keys(JSON.parse(account.resale.theme)).length === 0) {
      setTheme(defaultTheme)
      return
    }
    setTheme(_.merge(theme, JSON.parse(account.resale.theme)))
  }, [])
  // todo remove this as soon as possible
  const resaleId = decode(account.auth.accessToken)?.resaleId
  const isTargetResale = resaleId === '79638307-2d03-4780-8159-971b6a4c22a8'
  //const isMessageValid = new Date() < new Date('2024/11/16')
  return (
    <>
      {isTargetResale ? (
        <Panel sx={{ minHeight: '50vh', width: '1062px' }}>
          <PageHeader pageTitle='Aviso' />
          <p style={{ padding: '16px' }}>
            Olá, CLIENTE!<br /><br />
            Estamos reestruturando os nossos processos e queremos melhorar ainda mais nossos serviços e a parceria com você. Por isso, gostaríamos de convidá-lo a participar da nossa pesquisa de satisfação NPS.
            <br /><br />
            Sua opinião é super importante para nós e suas respostas vão nos ajudar a entender melhor suas necessidades e a melhorar nossos serviços. A pesquisa é rapidinha e vai levar só alguns minutinhos do seu tempo.
            <br /><br />
            Para participar, basta clicar no link abaixo:
            <br /><br />
            <a target='_blank' rel='noreferrer' href='https://bit.ly/PesquisaMotiva2024'>Microsoft Forms</a>
            <br /><br />
            Muito obrigado pela sua colaboração!
            <br /><br />
            Atenciosamente,
            <br /><br />
            Equipe Motiva
          </p>
          <Box
            sx={{
              padding: '32px',
              backgroundImage: `url(${banner})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              margin: 'auto',
              height: '686px',
              width: '1062px',
              position: 'relative'
            }}
          >
            <a target='_blank' rel='noreferrer' href='https://bit.ly/PesquisaMotiva2024'>
              <button
                style={{
                  all: 'unset',
                  height: '52px',
                  width: '286px',
                  position: 'absolute',
                  bottom: '85px',
                  left: '96px',
                  cursor: 'pointer',
                }}
              />
            </a>
          </Box>
        </Panel>
      ) : (
        <Content>
          <Image src={Img} />
        </Content>
      )}
    </>
  )
}

export default Home
